import request from '@/utils/request'

// 后台-考勤统计
export function getList(data) {
  return request({
    url: '/taskAdmin/attendance/attend-statistics',
    method: 'post',
    data,
  })
}

// 员工 分组 列表
export function peopleList(data) {
  return request({
    url: '/baseAdmin/user/index',
    method: 'post',
    data,
  })
}

// 考勤分组
export function attendanceList(data) {
  return request({
    url: 'setAdmin/attendance/list',
    method: 'post',
    data,
  })
}
// 部门下拉
export function departmentList(data) {
  return request({
    url: 'baseAdmin/common/depart-list',
    method: 'post',
    data,
  })
}

/**
 * 提成统计
 */

// 提成方案 列表
export function commissionList(data) {
  return request({
    url: '/taskAdmin/commission/index',
    method: 'post',
    data,
  })
}

// 提成方案详情
export function commissionDetail(data) {
  return request({
    url: '/taskAdmin/commission/view',
    method: 'post',
    data,
  })
}
// 提成统计 添加 / 修改
export function commissionAdd(data) {
  return request({
    url: '/taskAdmin/commission/modify',
    method: 'post',
    data,
  })
}

// 更新方案状态
export function changeStatus(data) {
  return request({
    url: '/taskAdmin/commission/status',
    method: 'post',
    data,
  })
}

// 查看核算

// 提成核算页面
export function checkAccounting(data) {
  return request({
    url: '/taskAdmin/commission/commission-info-view',
    method: 'post',
    data,
  })
}
// 核算本期提成蓝色按钮

export function accountingCommission(data) {
  return request({
    url: '/taskAdmin/commission/do-phase-business',
    method: 'post',
    data,
  })
}
// 提成核算列表
export function accountingList(data) {
  return request({
    url: '/taskAdmin/commission/commission-info-list',
    method: 'post',
    data,
  })
}
// 提交后台发放提成

export function submit(data) {
  return request({
    url: '/taskAdmin/commission/commission-phase-grant',
    method: 'post',
    data,
  })
}

// 用户明细详情
export function accountingDetail(data) {
  return request({
    url: '/taskAdmin/commission/commission-info-user',
    method: 'post',
    data,
  })
}
/**
 * 拜访拍照
 */

// 拜访拍照列表
export function pictureList(data) {
  return request({
    url: '/taskAdmin/visit/list',
    method: 'post',
    data,
  })
}

// 根据经纬度获取实际地址
export function getAddress(data) {
  return request({
    url: 'baseAdmin/common/get-address',
    method: 'post',
    data,
  })
}

// 查看客户所有照片
export function getAllPic(data) {
  return request({
    url: '/taskAdmin/visit/photo-cust-list',
    method: 'post',
    data,
  })
}

// 拍照类型列表的接口：
export function picTypeList(data) {
  return request({
    url: 'taskAdmin/visit/photo-type-list',
    method: 'post',
    data,
  })
}

// 拍照类型 添加
export function picTypeAdd(data) {
  return request({
    url: '/taskAdmin/visit/photo-type-add',
    method: 'post',
    data,
  })
}

// 拍照类型 删除
export function picTypeDelete(data) {
  return request({
    url: '/taskAdmin/visit/photo-type-del',
    method: 'post',
    data,
  })
}

// 拍照类型 修改
export function picTypeEdit(data) {
  return request({
    url: '/taskAdmin/visit/photo-type-edit',
    method: 'post',
    data,
  })
}

/**
 * 拜访统计
 */

// 拜访统计
export function visitList(data) {
  return request({
    url: '/taskAdmin/visit/summary-list',
    method: 'post',
    data,
  })
}

// 拜访汇总 异常弹窗
export function locationAbnormal(data) {
  return request({
    url: '/taskAdmin/visit/summary-abnormal',
    method: 'post',
    data,
  })
}

// 拜访汇总 漏店弹窗
export function getLineAbnormal(data) {
  return request({
    url: '/taskAdmin/visit/summary-omit',
    method: 'post',
    data,
  })
}

// 拜访统计 详情
export function visitDetail(data) {
  return request({
    url: '/taskAdmin/visit/summary-detail',
    method: 'post',
    data,
  })
}
/**
 * @任务管理
 */
// 首页表格数据
export function getTableList(data) {
  return request({
    url: '/taskAdmin/task/list',
    method: 'post',
    data,
  })
}
// 添加任务
export function createMission(data) {
  return request({
    url: '/taskAdmin/task/add',
    method: 'post',
    data,
  })
}

// 删除任务
export function deleteMission(data) {
  return request({
    url: '/taskAdmin/task/del',
    method: 'post',
    data,
  })
}

// 改变任务状态
export function changeMissionStatus(data) {
  return request({
    url: '/taskAdmin/task/status',
    method: 'post',
    data,
  })
}

// 任务详情
export function missionDetail(data) {
  return request({
    url: '/taskAdmin/task/info',
    method: 'post',
    data,
  })
}

// 修改任务
export function editMission(data) {
  return request({
    url: '/taskAdmin/task/update',
    method: 'post',
    data,
  })
}

/**
 * @任务达成
 */

//  任务达成列表
export function missionCompleteList(data) {
  return request({
    url: '/taskAdmin/task/order-list',
    method: 'post',
    data,
  })
}
// 任务详情
export function missionCompleteDetail(data) {
  return request({
    url: '/taskAdmin/task/order-info',
    method: 'post',
    data,
  })
}
// 保存关联业务员
export function subStaff(data) {
  return request({
    url: '/taskAdmin/task/update-user',
    method: 'post',
    data,
  })
}

/**
 * @新提成方案设置 人员列表
 */
export function staffData(data) {
  return request({
    url: '/taskAdmin/extract-info-detail/user-list',
    method: 'post',
    data,
  })
}
/**
 * @提成方案下拉
 */
export function commissionPlanSelect(data) {
  return request({
    url: '/taskAdmin/extract-info/index',
    method: 'post',
    data,
  })
}
